/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

// GENERAL
// ---------------

$color1:              #171919;
$color2:              #c63f1a;
$color3:              #9A2100;
$color4:              #4b5253;
$color5:              #f6f1eb;
$color6:              #f1ece6;
$color7:              #ddd9d3;
$color8:              #eedfce;

// TYPOGRAPHY
// ---------------

$textColor:           $color1;

$baseFontSize:        16px;
$em-base:             $baseFontSize;
$baseLineHeight:      1.612;
$baseTypeSpacing:     round($baseFontSize * $baseLineHeight);
$baseFontFamily:      'Work Sans', sans-serif;
$secondaryFontFamily: $baseFontFamily;

$headingsFontFamily:  $secondaryFontFamily;
$headingsColor:       $color1;

$hrColor:             #DDD;
